<script setup lang="ts">
import { ROUTE_NAV } from '@/shared/config'
import { AVEOLA_COMMUNITY_GUIDES_URL } from '~/src/shared/config/deeplink.js'
const localePath = useLocalePath()

const emit = defineEmits(['privacy', 'cookies', 'terms', 'purchase'])
</script>

<template>
  <div class="mx-auto flex flex-col justify-center">
    <NuxtLink
      tag="a"
      :to="localePath(ROUTE_NAV.policy)"
      class="text-h6-medium mb-12 text-center text-gray-500"
      @click="emit('privacy')"
    >
      {{ $t('shared.privacy_policy') }}
    </NuxtLink>
    <NuxtLink
      tag="a"
      :to="localePath(ROUTE_NAV.cookiePolicy)"
      class="text-h6-medium mb-12 text-center text-gray-500"
      @click="emit('cookies')"
    >
      {{ $t('footer.cookie_policy') }}
    </NuxtLink>
    <NuxtLink
      tag="a"
      target="_blank"
      :to="AVEOLA_COMMUNITY_GUIDES_URL"
      class="text-h6-medium mb-12 text-center text-gray-500"
    >
      {{ $t('shared.footer.community_guidlines') }}
    </NuxtLink>
    <NuxtLink
      tag="a"
      :to="localePath(ROUTE_NAV.terms)"
      class="text-h6-medium mb-12 text-center text-gray-500"
      @click="emit('terms')"
    >
      {{ $t('footer.terms_and_conditions_of_use') }}
    </NuxtLink>
    <NuxtLink
      tag="a"
      :to="localePath(ROUTE_NAV.purchaseTerms)"
      class="text-h6-medium mb-12 text-center text-gray-500"
      @click="emit('purchase')"
    >
      {{ $t('footer.purchase_and_subscription_terms') }}
    </NuxtLink>
    <NuxtLink
      tag="a"
      :to="localePath(ROUTE_NAV.doNotSellMyPersonalInfo)"
      class="text-h6-medium mb-12 text-center text-gray-500"
    >
      {{ $t('footer.do_not_send_my_personal_info') }}
    </NuxtLink>
  </div>
</template>
